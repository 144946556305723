import React, { Component } from "react";
//import config from "config";
import EpisodeComponent from "../EpisodeComponent.js";

import ApiHandler from "../../ApiHandler.js";

import { Link } from "react-router-dom";

import "../../styles/index.scss";
import Pagination from "../../lib/Pagination.js";

import Sidebar from "../sidebar/Sidebar";

import config from "config";

const banners = [
    {
        image: "./assets/img/banners/new/slide3.jpg",
        path: "/anime/jujutsu-kaisen-2nd-season"
    },
    {
        image: "./assets/img/banners/new/konosuba3hianimex.jpg",
        path: "/anime/kono-subarashii-sekai-ni-shukufuku-wo-3"
    },
    {
        image: "./assets/img/banners/new/kaiju8hianimex.jpg",
        path: "/anime/kaijuu-8-gou"
    },
    {
        image: "./assets/img/banners/new/sololeveling.jpg",
        path: "/anime/ore-dake-level-up-na-ken"
    },
    {
        image: "./assets/img/banners/new/shangrila2.jpg",
        path: "/anime/shangri-la-frontier-kusoge-hunter-kamige-ni-idoman-to-su"
    }
]

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.setState({ latest: [] });
        this.setState({ latestPage: 0, latestAnimePage: 0, trendingAnimePage: 0 });
    }




    componentDidMount() {

        const sliderJS = `

var slide = document.getElementById('slide');

var now=0;
var menu=0;
var mv=4;
var pos=0;
var elem=5;

var smove = setInterval(function () {nextm()}, 3500);

function prewm()
{

  now--;
  if(now<0)
  {
    now=elem-1;
  }
  slide.className='slidermain'+now;
  clearInterval(smove);
  smove = setInterval(function () {nextm()}, 3500);
}
function nextm()
{

  now++;
  slide.className='slidermain'+now;
  if(now>=elem)
  {
    now=0;
    slide.className='slidermain'+now;
  }
  clearInterval(smove);
  smove = setInterval(function () {nextm()}, 3500);
}

		`;

        const slider = document.createElement("script");

        slider.innerText = sliderJS;

        document.body.appendChild(slider);


        ApiHandler
            .get("/latest")
            .then(d => this.setState({ latest: d }))

    }

    render() {



        const { latest } = this.state;

        return (
            <>
                <div className="slider">
                    <div className="sliderpnbox">
                        <div className="sliderp" onClick={() => eval("prewm()")}>
                        </div>
                        <div className="slidern" onClick={() => eval("nextm()")}>
                        </div>
                    </div>
                    <div className="slidermain1" id="slide">
                        <div className="centerf">
                            <div className="centerf2">
                                {banners.map(banner => <Link className="an" to={banner.path}>
                                    <img className="sliderimg" src={banner.image} />
                                </Link>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="notmain">
                    <div class="maindark">
                        <div class="iepbox">
                            <div id="episodes-subbed">
                                {latest &&
                                    this.buildEpisodes(
                                        latest.latest_episodes,
                                        this.state.latestPage,
                                        "latestPage",
                                        EpisodeComponent,
                                        "Recent updates")}
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
            </>
        )

    }



    /*<EpisodeComponent episode={v} />*/

    buildEpisodes(items, current, key, component, title) {

        const pagination = new Pagination(items)
            .perPage(35)
            .padding(1, 1)
            .gutter(1, 1)
            .get(current);

        return (
            <>
                {pagination.nav.currentPage.items.map((v, i) =>
                    React.createElement(component, {
                        payload: v,
                        key: i + "key",
                        index: i
                    })
                )}
            </>
        )

    }

}





export default Index