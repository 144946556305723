import React from "react";
import { useRecoilState } from "recoil";
import { showDonationPopup } from "../../state/models";

import Popup from "../../lib/popup/Popup";
import config from "config";

import "../../styles/donate.scss";

function DonateButton(props) {

    const [shown, setShown] = useRecoilState(showDonationPopup);

    const onClick = e => {
        e.preventDefault();

        setShown(true);
    }

    return <>
        
    </>;

}

export default DonateButton;