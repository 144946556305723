import React, { Component } from "react";

import DocumentMeta from "react-document-meta";
import "../../../styles/nopage.scss";


export default class Error404 extends Component {

	render() {

		const meta = {

				title: `404`,
			    description: `404`,
			    meta: {
			        charset: "utf-8",
			        name: {
			            keywords: `404`
			        }
			    }
			
			}

		return (
			<div class="hai404page">
			<DocumentMeta {...meta} />
			HIANIME Hi-anime HiAnime Hi Anime Aniwatch Watch Anime Online, Free Anime to Watch, Best Anime Website, Anime Website Similar to Animeheaven, Anime Website Similar to Kissanime.ru, New Anime Website, No Ads, Anime, Watch, Online, Best Anime Website 2021, Best Anime Website 2022,
			Best Anime Website 2023, Best Anime Website 2024, Best Anime Website 2025, 9anime, KissAnime, AnimeHeaven, New Website Masterani, Anilinkz, AnimeCrazy.net, Chia-Anime, Animekisa, AnimeGlare, AnimeDao	<a href="https://hianimex.to"> Watch Now</a>
			</div>
		)

	}

}


